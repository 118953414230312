import React, { useState, useEffect } from "react";
import SEO from "../SEO";
import styled from "styled-components";
import Check from "../../assets/img/check.svg";
import CheckNe from "../../assets/img/checkNe.svg";
import CheckJo from "../../assets/img/checkJo.svg";
import Telefon from "../../assets/img/telefon.svg";
import SelectImg from "../../assets/img/select.svg";
import Img from "gatsby-image";
import axios from 'axios';
import { StaticQuery, graphql } from "gatsby";
import { navigate } from "gatsby";
//import { createTransport } from "nodemailer";
import { useForm, useWatch } from "react-hook-form";
import Loading from "../../assets/img/loading.svg";





const ObjednavkaFormInner = styled.div`
	.jumbotron {
		background: #F7F7F7;
		padding: 66px 0;

		@media (max-width: 900px) {
			padding: 30px 0;
		}

		h1 {
			font-family: NY-Medium;
			font-size: 32px;
			color: #000000;
			max-width: 1150px;
			width: calc(100% - 50px);
			margin: 0 auto;

			@media (max-width: 900px) {
				font-size: 25px;
				line-height: 34px;
			}
		}
	}

	.objednavkaBody {
		padding-bottom: 60px;
		max-width: 1150px;
		width: calc(100% - 50px);
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		vertical-align: top;
		margin-top: 70px;

		@media (max-width: 900px) {
			width: calc(100%);
			margin-top: 0px;
			display: block;
			padding: 0;
		}

		.left {
			max-width: 600px;
			width: calc(100% - 400px);
			order: 1;

			@media (max-width: 900px) {
				margin: 0 auto;
				width: calc(100% - 50px);
				margin-top: 40px;
				margin-bottom: 60px;
				max-width: 600px;
			}
			
			h2 {
				margin: 0 auto;
				margin-bottom: 40px;
				font-family: Visuelt-Medium;
				font-size: 24px;
				color: #000000;
			}

			.druheH2 {
				margin-top: 60px;
			}

			label {
				display: block;
				margin-bottom: 30px;

				.errorMsg {
					font-family: Visuelt-Regular;
					line-height: 25px;
					font-size: 16px;
					color: #FF6060;
					margin: 0;
					margin-top: 8px;
				}

				span {
					display: block;
					font-family: Visuelt-Regular;
					font-size: 16px;
					color: #6C6C6C;
					line-height: 25px;
					margin-bottom: 8px;
				}

				input[type=text] {
					background: #FFFFFF;
					border: 2px solid #D2D2D2;
					border-radius: 5px;
					width: calc(100% - 24px);
					padding: 10px;
					font-family: Visuelt-Regular;
					font-size: 16px;
					color: #000;
					line-height: 25px;

					&:focus {
						border: 2px solid #566FEF;
					}
				}

				input[type=email] {
					background: #FFFFFF;
					border: 2px solid #D2D2D2;
					border-radius: 5px;
					width: calc(100% - 24px);
					padding: 10px;
					font-family: Visuelt-Regular;
					font-size: 16px;
					color: #000;
					line-height: 25px;

					&:focus {
						border: 2px solid #566FEF;
					}
				}

				select {
					background: #FFFFFF;
					border: 2px solid #D2D2D2;
					border-radius: 5px;
					padding: 10px;
					font-family: Visuelt-Regular;
					font-size: 16px;
					color: #000;
					line-height: 25px;
					-moz-appearance: none;
					-webkit-appearance: none;
					appearance: none;
					background-image: url(${SelectImg});
					background-repeat: no-repeat;
					background-position: 92% 50%;
					padding-right: 60px;
					width: calc(100% - 4px);
					max-width: 350px;

					&:focus {
						border: 2px solid #566FEF;
					}
				}

				input[type=file] {
					background: #FFFFFF;
					border: 2px solid #D2D2D2;
					border-radius: 5px;
					padding: 10px;
					font-family: Visuelt-Regular;
					font-size: 16px;
					color: #000;
					max-width: calc(330px - 4px);
					line-height: 25px;

					@media (max-width: 900px) {
						width: calc(100% - 24px);
					}

					&:focus {
						border: 2px solid #566FEF;
					}
				}

				textarea {
					background: #FFFFFF;
					border: 2px solid #D2D2D2;
					border-radius: 5px;
					padding: 10px;
					font-family: Visuelt-Regular;
					font-size: 16px;
					color: #000;
					line-height: 25px;
					width: calc(100% - 24px);
					resize: none;
					height: 190px;

					&:focus {
						border: 2px solid #566FEF;
					}
				}
			}

			.inputError {
				input {
					border: 2px solid #FF6060 !important;
				}

				textarea {
					border: 2px solid #FF6060 !important;
				}

				select {
					border: 2px solid #FF6060 !important;
				}

				span {
					color: #FF6060;
				}

				.fakeCheckbox {
					border: 2px solid #FF6060 !important;
				}

				p {
					color: #FF6060;

					a {
						color: #FF6060 !important;
					}
				}
			}

			.checkbox {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&:focus {
					.fakeCheckbox {
						border: 2px solid #566FEF;
					}
				}

				input {
					display: none;

					&:focus {
						& + .fakeCheckbox {
							border: 2px solid #566FEF;
						}
					}
				}

				.fakeCheckbox {
					background: #FFFFFF;
					background-image: url(${CheckNe});
					background-position: center;
					background-repeat: no-repeat;
					background-size: 70%;
					border: 2px solid #D2D2D2;
					border-radius: 5px;
					width: 25px;
					height: 25px;
					cursor: pointer;
				}

				input:checked + .fakeCheckbox {
					background: #566FEF;
					background-image: url(${CheckJo});
					border: 2px solid #566FEF;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 70%;
				}

				p {
					width: calc(100% - 40px - 15px);

					@media (max-width: 900px) {
						width: calc(100% - 50px);
					}

					a {
						color: #000;
					}
				}
			}
			
			.submit {
				font-family: Visuelt-Medium;
				font-size: 18px;
				color: #000000;
				text-align: center;
				background: rgb(255, 238, 98);
				border-radius: 5px;
				max-width: 290px;
				width: 100%;
				border: 0;
				padding: 13px 0;
				margin-top: 30px;
				cursor: pointer;

				&:hover {
					background: #EFD83E;
				}
			}
		}

		.right {
			order: 2;
			max-width: 440px;
			min-width: 370px;
			width: calc(100% - 710px);
			background: #F7F7F7;
			padding-top: 25px;
			padding-bottom: 25px;
			height: fit-content;

			@media (max-width: 900px) {
				width: calc(100%);
				max-width: none;
				min-width: 0;
				padding: 0;
				padding-top: 10px;
				padding-bottom: 20px;
			}

			.rightInner {
				@media (max-width: 900px) {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					width: calc(100% - 50px);
					margin: 0 auto;
				}

				@media (max-width: 750px) {
					display: block;
					width: calc(100%);
				}
			}

			.nabidka {
				background: #FFFFFF;
				border: 1px solid #D2D2D2;
				border-radius: 5px;
				width: calc(100% - 50px - 50px);
				margin: 0 auto;
				margin-bottom: 25px;
				padding: 25px;

				@media (max-width: 900px) {
					margin: 0;
					margin-bottom: 20px;
					width: calc(100% - 430px);
					max-width: 380px;
				}

				@media (max-width: 750px) {
					width: calc(100% - 20px - 50px - 25px - 2px);
					margin-left: 25px;
				}

				h3 {
					font-family: Visuelt-Medium;
					font-size: 22px;
					color: #000000;
					line-height: 24px;
					margin-bottom: 30px;
				}

				ul {
					border-bottom: 2px solid #000000;
					padding-bottom: 15px;

					li {
						list-style: none;
						margin-bottom: 15px;
						display: flex;

						img {
							display: block;
						}

						span {
							display: block;
							margin-left: 14px;
						}
					}
				}

				.cena {
					font-family: Visuelt-Bold;
					font-size: 23px;
					color: #000000;
					margin-top: 25px;
					display: block;

					span {
						font-family: Visuelt-Bold;
						font-size: 18px;
					}
				}
			}

			.supportBox {
				background: #FFFFFF;
				border: 1px solid #D2D2D2;
				border-radius: 5px;
				width: calc(100% - 50px - 50px);
				padding: 25px;
				margin: 0 auto;

				@media (max-width: 900px) {
					width: calc(100% - 20px - 50px);
					max-width: 300px;
					margin: 0;
				}

				@media (max-width: 750px) {
					margin-left: 25px;
					width: calc(100% - 20px - 50px - 25px - 2px);
					max-width: 380px;
				}

				h3 {
					font-family: Visuelt-Medium;
					font-size: 22px;
					margin-bottom: 30px;
					color: #000000;
				}

				a {
					background: #FFEB66;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 16px 0px;
					max-width: 300px;
					width: calc(100%);

					span {
						font-family: Visuelt-Medium;
						font-size: 30px;
						color: #000000;
						margin-left: 15px;

						@media (max-width: 750px) {
							font-size: 22px;
						}
					}

					img {
						height: 25px;
						width: 25px;
						position: relative;
						top: -2px;

						@media (max-width: 750px) {
							height: 18px;
							top: -1px;
							width: 18px;
						}
					}
				}

				.kDispozici {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 258px;
					margin-top: 25px;

					@media (max-width: 750px) {
						width: 100%;
						max-width: 258px;
					}

					.obrazek {
						
					}

					div {
						.greenDot {
							background: #49B25D;
							height: 10px;
							width: 10px;
							border-radius: 100%;
							animation: pulse 2s infinite;
							display: inline-block;
							vertical-align: middle;
						}

						.text {
							font-family: Visuelt-Medium;
							font-size: 16px;
							color: #48B15C;
							line-height: 24px;
							vertical-align: middle;
							margin-left: 10px;

							@media (max-width: 750px) {
								margin-left: 5px;
							}
						}
					}
				}
			}
		}
	}

	@keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 1;
    }
  }
`;




const ObjednavkaForm = () => {
	
	
	const [jwt, setJwt] = useState("");
  const { register, handleSubmit, errors, control } = useForm(); //watch



	const onSubmit = data => handleForm(data, jwt);
	const [isLoading, setIsLoading] = useState(false);


	const maritalStatusState = useWatch({
    control,
    name: 'maritalStatus', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: 'Nevybráno' // default value before the render
  });





	// nastavení správné backend url

	let backendUrl = "";

	if (process.env.NODE_ENV === 'development') {
		backendUrl = 'http://localhost:1337'
	}
	
	if (process.env.NODE_ENV === 'production') {
		backendUrl = 'https://goodbye-backend.herokuapp.com'
	}

	async function handleForm (r, jwt) {
		setIsLoading(true);
		
		// Uložit kontakt do fakturoidu

		const resVytvoritKontakt = await axios.post(`${backendUrl}/vytvorkontakt`, {
				"custom_id": null,
				"name": r.name,
				"street": r.address,
				"street2": null,
				"city": r.city,
				"zip": r.postCode,
				"country": "CZ",
				"registration_no": "",
				"vat_no": "",
				"bank_account": "",
				"iban": "",
				"variable_symbol": "",
				"full_name": r.name,
				"email": r.email,
				"email_copy": "",
				"phone": r.phoneNumber,
				"web": ""
			},
			{
				headers: {
					Authorization: `Bearer ${jwt}`
				},
			}
		);

		// console.log("kontakt done");


		// Vytvorit fakturu z kontaktu 

		const resVytvoritFakturu = await axios.post(`${backendUrl}/vytvorfakturu`, {
			"client_name": r.name,
			"subject_id": resVytvoritKontakt.data.id,
			"currency": "CZK",
			"payment_method": "bank",
			"proforma": true,
			"due": 7,
			"lines": [
				{
					"name": "Kremace bez obřadu - Goodbye.cz",
					"quantity": "1",
					"unit_name": "",
					"unit_price": "13900",
					"vat_rate": "12"
				}
			]
		}, {
			headers: {
				Authorization: `Bearer ${jwt}`
			},
		});

		// console.log("faktura done");
		
		//
		// Strapi část
		//

   	// upload textů

		const objednavkaMongoData = {
			"name": r.name,
			"email": r.email,
			"phoneNumber": r.phoneNumber,
			"address": r.address,
			"city": r.city,
			"postCode": r.postCode,
			"relationship": r.relationship,
			"nameDead": r.nameDead,
			"birthDead": r.birthDead,
			"birthPlaceDead": r.birthPlaceDead,
			"addressDead": r.addressDead,
			"personalIdNumber": r.personalIdNumber,
			"maritalStatus": r.maritalStatus,
			"timeOfDeath": r.timeOfDeath,
			"placeOfDeath": r.placeOfDeath,
			"pickupPlace": r.pickupPlace,
			"notes": r.notes,
			"maidenName": r.maidenName,
			"partnerMaidenName": r.partnerMaidenName,
			"partnerBirthdate": r.partnerBirthdate,
			"partnerIdNumber": r.partnerIdNumber,
		}

		const resPost = await axios.post(`${backendUrl}/orders`, objednavkaMongoData, {
			headers: {
				Authorization: `Bearer ${jwt}`
			},
		});

		// console.log("data uploaded");




		let photoStatus = [
			{
				name: "idBack",
				state: "unset",
				data: new FormData(),
				res: undefined,
			},
			{
				name: "idFront",
				state: "unset",
				data: new FormData(),
				res: undefined,
			},
			{
				name: "idDeadFront",
				state: "unset",
				data: new FormData(),
				res: undefined,
			},
			{
				name: "idDeadBack",
				state: "unset",
				data: new FormData(),
				res: undefined,
			},
			{
				name: "deathCertificate",
				state: "unset",
				data: new FormData(),
				res: undefined,
			},
		];

		photoStatus.forEach(obrazek => {
			if (obrazek.name === "idBack") {
				// console.log(`r.idBack[0]:`);
				// console.log(r.idBack[0]);

				if (r.idBack[0] === undefined) {
					obrazek.state = "nevyplneno";
				}
				
				obrazek.data.append("files", r.idBack[0]);
			}

			if (obrazek.name === "idFront") {
				// console.log(`r.idFront[0]:`);
				// console.log(r.idFront[0]);

				if (r.idFront[0] === undefined) {
					obrazek.state = "nevyplneno";
				}

				obrazek.data.append("files", r.idFront[0]);
			}

			if (obrazek.name === "idDeadFront") {
				// console.log(`r.idDeadFront[0]:`);
				// console.log(r.idDeadFront[0]);

				if (r.idDeadFront[0] === undefined) {
					obrazek.state = "nevyplneno";

				}

				obrazek.data.append("files", r.idDeadFront[0]);
			}

			if (obrazek.name === "idDeadBack") {
				// console.log(`r.idDeadBack[0]:`);
				// console.log(r.idDeadBack[0]);

				if (r.idDeadBack[0] === undefined) {
					obrazek.state = "nevyplneno";
				}

				obrazek.data.append("files", r.idDeadBack[0]);
			}

			if (obrazek.name === "deathCertificate") {
				// console.log(`r.deathCertificate[0]:`);
				// console.log(r.deathCertificate[0]);

				if (r.deathCertificate[0] === undefined) {
					obrazek.state = "nevyplneno";
				}

				obrazek.data.append("files", r.deathCertificate[0]);
			}

			if (obrazek.data.getAll("files")[0] !== "undefined") {
				obrazek.state = "false";
			}
		});

		let files = photoStatus.filter(obrazek => obrazek.state === "false");


	
		// console.log(photoStatus);
		// console.log(files);

		
		let pocetSouboru = files.length;

		// console.log("pocetSouboru " + pocetSouboru);


		files.forEach(file => {
			// console.log("starting upload" + file.name);

			axios.post(`${backendUrl}/upload`, file.data, {
				headers: {
					Authorization: `Bearer ${jwt}`
				},
			}).then(res => {
				// console.log("uploaduto" + file.name);

				file.state = "uploaded";
				file.res = res.data[0];

				let uzUploadlich = 0;

				files.forEach(element => {
					if (element.state === "uploaded") {
						uzUploadlich++;

						// console.log("uzUploadlich " + uzUploadlich);
					}

					if (uzUploadlich === pocetSouboru) {

						let dataNaUpdate = {
							"invoiceLink": resVytvoritFakturu.data.html_url,
							"invoiceNumber": resVytvoritFakturu.data.number,
							"variableSymbol": resVytvoritFakturu.data.variable_symbol,
							"idBack": undefined,
							"idFront": undefined,
							"idDeadFront": undefined,
							"idDeadBack": undefined,
							"deathCertificate": undefined,
						}

						files.forEach(element => {
							dataNaUpdate[element.name] = element.res;
						});

						// console.log(dataNaUpdate);


						axios.put(`${backendUrl}/orders/${resPost.data.id}`, dataNaUpdate,
						{
							headers: {
								Authorization: `Bearer ${jwt}`
							},
						}).then(res => {
								// console.log("data update");

								// email notifikace

								axios.post(`${backendUrl}/poslimail`, {
									"to": 'podpora@goodbye.cz',
									"from": 'podpora@goodbye.cz',
									"subject": `Nová objednávka - ${r.name}`,
									"text": `Nová objednávka - ${r.name}`,
									"html": `<h1>Nová objednávka - ${r.name}</h1><br><pre>${JSON.stringify(r, null, 2)}</pre>`,
								},
								{
									headers: {
										"Authorization": `Bearer ${jwt}`,
										"Content-Type": "application/json"
									},
								}).then(res => {
									// console.log("email");

									//přesměrovat
	
									navigate(`/objednavka/${resPost.data.id}`);
									setIsLoading(false);
								});
							}
						);
					}
				});				
			});
		});
	}
	
	useEffect(() => {
    // api

		axios.post(`${backendUrl}/auth/local`, {
			identifier: 'app@goodbye.cz',
			password: 'Good.Bye123',
		}).then(res => {
			setJwt(res.data.jwt);

			// console.log("API ready");
		});
  }, [backendUrl]);




	return (
		<>
			<SEO
				title="Objednávkový formulář"
				description="Vyplňte prosím tuto závaznou objednávku."
				noindex={true}
			/>
			<section>
				{(!isLoading)
				?			
				<ObjednavkaFormInner>
					<div className="jumbotron">
						<h1>Vyplňte prosím tuto závaznou objednávku.</h1>
					</div>
					<div className="objednavkaBody">
						<div className="right">

							<div className="rightInner">

								{/* <div className="nabidka">
									<h3>Goodbye Kremace</h3>
									<ul>
										<li>
											<img src={Check} alt="check"/>
											<span>Přeprava vašeho blízkého k nám</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Vyřízení veškerých dokumentů</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Samotná kremace včetně poplatků</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Doručení urny s popelem až k vám domů</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Podpora na telefonu 24/7</span>
										</li>
									</ul>
									<span className="cena">13 900<span> Kč (vč. DPH)</span></span>
								</div> */}

								<div className="supportBox">
									<h3>Potřebujete se s námi poradit?</h3>
									<a href="tel:00420315558136">
										<img src={Telefon} alt="Telefon" />
										<span>315 558 136</span>
									</a>
									<div className="kDispozici">
										<StaticQuery
											query={graphql`
												query {
													file(relativePath: { eq: "podpora.png" }) {
														childImageSharp {
															fixed(width: 100, quality: 100) {
																...GatsbyImageSharpFixed_noBase64
															}
														}
													}
												}
											`}
											render={data => (
												<Img className="obrazek" alt="Naše podpora" fixed={data.file.childImageSharp.fixed} />
											)}
										/>
										<div>
											<span className="greenDot"></span>
											<span className="text">K dispozici 24/7</span>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="left">
							<form onSubmit={handleSubmit(onSubmit)}>

								<h2>Osobní informace o vás (objednavateli)</h2>
								<label className={errors.name && "inputError"}>
									<span>Vaše celé jméno a příjmení</span>
									<input ref={register({ required: true })} type="text" name="name"/>
									{errors.name && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.email && "inputError"}>
									<span>E-mail</span>
									<input ref={register({ required: true })} type="email" name="email"/>
									{errors.email && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.phoneNumber && "inputError"}>
									<span>Telefonní číslo</span>
									<input ref={register({ required: true })} type="text" name="phoneNumber"/>
									{errors.phoneNumber && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.address && "inputError"}>
									<span>Ulice a číslo popisné</span>
									<input ref={register({ required: true })} type="text" name="address"/>
									{errors.address && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.city && "inputError"}>
									<span>Město</span>
									<input ref={register({ required: true })} type="text" name="city"/>
									{errors.city && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.postCode && "inputError"}>
									<span>PSČ</span>
									<input ref={register({ required: true })} type="text" name="postCode"/>
									{errors.postCode && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.relationship && "inputError"}>
									<span>Jaký je váš vztah k zesnulému?</span>
									<select ref={register({ required: true })} name="relationship">
										<option value="">Vybrat</option>
										<option value="Jsem manžel/ka">Jsem manžel/ka</option>
										<option value="Jsem potomek">Jsem potomek</option>
										<option value="Jsem rodič">Jsem rodič</option>
										<option value="Jsem prarodič">Jsem prarodič</option>
										<option value="Jsem vnuk/vnučka">Jsem vnuk/vnučka</option>
										<option value="Jsem sourozenec">Jsem sourozenec</option>
										<option value="Jsem zmocněnec těchto osob">Jsem zmocněnec těchto osob</option>
										<option value="Žil/a jsem ve stejné domácnosti">Žil/a jsem ve stejné domácnosti</option>
										<option value="Něco jiného">Něco jiného</option>
									</select>
									{errors.relationship && <div className="errorMsg">Toto pole je povinné</div>}
								</label>

								<label className={errors.idFront && "inputError"}>
									<span>Fotka vašeho občanského průkazu (přední strana)</span>
									<input ref={register({ required: true })} name="idFront" type="file"/>
									{errors.idFront && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.idBack && "inputError"}>
									<span>Fotka vašeho občanského průkazu (zadní strana)</span>
									<input ref={register({ required: true })} name="idBack" type="file"/>
									{errors.idBack && <div className="errorMsg">Toto pole je povinné</div>}
								</label>



								<h2 className="druheH2">Údaje o zesnulém</h2>
								<label className={errors.nameDead && "inputError"}>
									<span>Celé jméno a příjmení</span>
									<input ref={register({ required: true })} name="nameDead" type="text"/>
									{errors.nameDead && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.maidenName && "inputError"}>
									<span>Jméno za svobodna (nepovinné)</span>
									<input ref={register()} type="text" name="maidenName"/>
								</label>
								<label className={errors.birthDead && "inputError"}>
									<span>Datum narození</span>
									<input ref={register({ required: true })} name="birthDead" type="text"/>
									{errors.birthDead && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.birthPlaceDead && "inputError"}>
									<span>Místo narození</span>
									<input ref={register({ required: true })} type="text" name="birthPlaceDead"/>
									{errors.birthPlaceDead && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.addressDead && "inputError"}>
									<span>Adresa trvalého bydliště</span>
									<input ref={register({ required: true })} type="text" name="addressDead"/>
									{errors.addressDead && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.personalIdNumber && "inputError"}>
									<span>Rodné číslo</span>
									<input ref={register({ required: true })} type="text" name="personalIdNumber"/>
									{errors.personalIdNumber && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.maritalStatus && "inputError"}>
									<span>Rodinný stav zesnulého</span>
									<select ref={register({ required: true })} name="maritalStatus">
										<option value="">Vybrat</option>
										<option value="Svobodný/Svobodná">Svobodný/Svobodná</option>
										<option value="Ženatý/vdaná">Ženatý/vdaná</option>
										<option value="Rozvedený/rozvedená">Rozvedený/rozvedená</option>
										<option value="Vdovec/vdova">Vdovec/vdova</option>
									</select>
									{errors.maritalStatus && <div className="errorMsg">Toto pole je povinné</div>}
								</label>

								{(maritalStatusState === "Ženatý/vdaná") && <>
									<label className={errors.partnerMaidenName && "inputError"}>
										<span>Celé jméno manžela/manželky - včetně jména za svobodna u žen</span>
										<input ref={register()} type="text" name="partnerMaidenName"/>
									</label>

									<label className={errors.partnerBirthdate && "inputError"}>
										<span>Datum narození manžela/manželky</span>
										<input ref={register()} type="text" name="partnerBirthdate"/>
									</label>

									<label className={errors.partnerIdNumber && "inputError"}>
										<span>Rodné číslo manžela/manželky</span>
										<input ref={register()} type="text" name="partnerIdNumber"/>
									</label>
								</>}

								<label className={errors.idDeadFront && "inputError"}>
									<span>Fotka přední strany občanského průkazu zesnulého (pokud je k dispozici)</span>
									<input ref={register()} name="idDeadFront" type="file"/>
								</label>
								<label className={errors.idDeadBack && "inputError"}>
									<span>Fotka zadní strany občanského průkazu (pokud je k dispozici)</span>
									<input ref={register()} name="idDeadBack" type="file"/>
								</label>
								<label className={errors.deathCertificate && "inputError"}>
									<span>List o prohlídce zemřelého (pokud je k dispozici)</span>
									<input ref={register()} name="deathCertificate" type="file"/>
								</label>
								<label className={errors.timeOfDeath && "inputError"}>
									<span>Datum a čas úmrtí</span>
									<input ref={register({ required: true })} name="timeOfDeath" type="text"/>
									{errors.timeOfDeath && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.placeOfDeath && "inputError"}>
									<span>Místo úmrtí</span>
									<input ref={register({ required: true })} name="placeOfDeath" type="text"/>
									{errors.placeOfDeath && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.pickupPlace && "inputError"}>
									<span>Místo vyzvednutí zesnulého</span>
									<input ref={register({ required: true })} name="pickupPlace" type="text"/>
									{errors.pickupPlace && <div className="errorMsg">Toto pole je povinné</div>}
								</label>
								<label className={errors.notes && "inputError"}>
									<span>Jakékoliv další poznámky</span>
									<textarea ref={register()} name="notes" />
								</label>
								<label className={`checkbox ${errors.checkBox1 && "inputError"}`}>
									<input ref={register({ required: true })} name="checkBox1" type="checkbox" />
									<div className="fakeCheckbox"></div>
									<p>Vytvořením objednávky uděluji společnosti Goodbye s.r.o. souhlas k převzetí a převozu zesnulého</p>
								</label>
								<label className={`checkbox ${errors.checkBox2 && "inputError"}`}>
									<input ref={register({ required: true })} name="checkBox2" type="checkbox" />
									<div className="fakeCheckbox"></div>
									<p>Souhlasím s <a target="_blank" href="/obchodni-podminky/">obchodními podmínkami společnosti Goodbye s.r.o.</a></p>
								</label>

								<input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Pokračovat"} />
							</form>
						</div>
					</div>
				</ObjednavkaFormInner>
				:
				<div className="loadingAnimationWrap">
					<img className="loadingAnimation" src={Loading} alt="Načítání" />
				</div>
				}	
			</section>
		</>
	);
}

export default ObjednavkaForm;
